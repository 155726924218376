/* Header Component */

.headerlogo {
	width: 100px;
	height: 100%;
}

/* Branding Hero */

.branding-container {
	/*   */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	/* border: 1px solid rgb(191, 33, 33); */
}

.brandingsection {
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 50px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.heroimg {
	/* border: 2px solid blue; */
}

.lilitaoneblack {
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.lilitaoneblack2 {
	font-family: "Poppins", sans-serif;
	font-size: 30px;
	color: #4a4a4a;
}

.biddocument {
	width: 280px;
}

.lilitaonered {
	font-family: "Poppins", sans-serif;
	padding: 5px;
	color: #4a4a4a;
}

.brandingvalueprop {
	/* border: 2px solid rgb(217, 255, 0); */
}

.phonetilt {
	/* border: 3px solid rgb(17, 0, 255); */
}

/* Email Hero */

.emailhero {
	background-color: #ff695d;
	/* border: 2px solid blue; */
	border-radius: 22px;
}

.input-group {
	/* display: flex; */
	justify-content: center;
}

.signupearly {
	border-radius: 5px !important;
	margin-right: 5px;
	max-width: 400px;
	border: 1px solid rgb(255, 255, 255);
}

.submitbutton {
	color: #ff695d;
	background-color: white;
	border-color: white;
	font-family: "Didact Gothic", sans-serif;
	font-weight: bold;
}

/* Feature Hero */

.featureherosection {
	/* border: 1px solid rgb(255, 115, 0); */
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 50px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.headersize {
	/* height: 150px; */
	/* border: 2px solid rgb(113, 125, 3); */
	display: flex;
	justify-content: center;
	/* align-items: center; */
}

.featureitemheader {
	padding: 15px;
	font-size: medium;
	font-weight: bold;
}

.icon {
	width: 50px;
	height: 50px;
	border-color: #5200bd;
}

.feature {
	max-width: 230px;
	min-height: 150px;
	/* border: 2px solid rgb(125, 58, 3); */
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.feature-blocks {
	display: flex;
	justify-content: center;
}

.escrow {
	height: 240px;
	width: auto;
	padding: 20px;
}

.addproperty {
	height: 250px;
	width: auto;
	padding: 10px;
}

.speciality {
	height: 250px;
	width: auto;
	padding: 10px;
}

/* Service Provider Hero */

.serviceproherosection {
	/* border: 1px solid rgb(255, 115, 0); */
	border-bottom: 1px solid rgb(255, 115, 0);
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 50px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.serviceproviderherosection {
	/* border: 1px solid rgb(255, 115, 0); */
	border-bottom: 1px solid rgb(255, 115, 0);
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 50px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.servicepicture {
	width: 370px;
	/* padding: 15px; */
	/* border: 2px solid rgb(180, 16, 16); */
}

.servicephone {
	/* border: 3px solid rgb(17, 0, 255); */
}

.providerphone {
	/* border-bottom: 1px solid #ff695d; */
}

#underline {
	text-decoration: underline;
}

.strong {
	/* font-family: "Poppins", sans-serif; */
	color: #ff695d;
}

.lilitaonewhite {
	font-family: "Poppins", sans-serif;
	color: #ffffff;
}

p {
	font-family: "Didact Gothic", sans-serif;
}

/* Investor Hero */

.herosection1 {
	border: 1px solid rgb(255, 115, 0);
	margin-top: 5px;
	margin-bottom: 5px;
	padding-bottom: 15px;
	padding-top: 20px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.herosection {
	border: 1px solid rgb(255, 115, 0);
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 50px;
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
}

.bi {
	font-size: xx-large;
}

.subtitles {
	/* margin-left: 350px;
	margin-right: 350px; */
	/* max-width: 800px; */
}

.icon {
	width: 50px;
	height: 50px;
	border-color: #5200bd;
}

.investorpicture {
	width: 370px;

	/* border: 2px solid rgb(180, 16, 16); */
}
/* Style your video iframe as needed */
.iframe {
	/* border: 2px solid rgb(180, 16, 16); */
	height: 250px;
}

.herovideo {
	/* border: 5px solid rgb(3, 125, 76); */
	display: flex;
	flex-direction: column; /* Stack items vertically */
	justify-content: center; /* Center vertically */
	text-align: center; /* Center text */
}

.branding-containervideo {
	/* border: 5px solid rgb(74, 8, 229); */
	/* display: flex;
	justify-items: center;
	align-items: center; */
}

.phonedislay {
	/* border: 5px solid rgb(8, 229, 85); */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Footer2 CS */

.footer_area {
	position: relative;
	z-index: 1;
	overflow: hidden;
	webkit-box-shadow: 0 8px 48px 8px rgba(47, 91, 234, 0.175);
	box-shadow: 0 8px 48px 8px rgba(47, 91, 234, 0.175);
	padding: 60px;
}
.footer_area .row {
	margin-left: -25px;
	margin-right: -25px;
}
.footer_area .row .col,
.footer_area .row .col-1,
.footer_area .row .col-10,
.footer_area .row .col-11,
.footer_area .row .col-12,
.footer_area .row .col-2,
.footer_area .row .col-3,
.footer_area .row .col-4,
.footer_area .row .col-5,
.footer_area .row .col-6,
.footer_area .row .col-7,
.footer_area .row .col-8,
.footer_area .row .col-9,
.footer_area .row .col-auto,
.footer_area .row .col-lg,
.footer_area .row .col-lg-1,
.footer_area .row .col-lg-10,
.footer_area .row .col-lg-11,
.footer_area .row .col-lg-12,
.footer_area .row .col-lg-2,
.footer_area .row .col-lg-3,
.footer_area .row .col-lg-4,
.footer_area .row .col-lg-5,
.footer_area .row .col-lg-6,
.footer_area .row .col-lg-7,
.footer_area .row .col-lg-8,
.footer_area .row .col-lg-9,
.footer_area .row .col-lg-auto,
.footer_area .row .col-md,
.footer_area .row .col-md-1,
.footer_area .row .col-md-10,
.footer_area .row .col-md-11,
.footer_area .row .col-md-12,
.footer_area .row .col-md-2,
.footer_area .row .col-md-3,
.footer_area .row .col-md-4,
.footer_area .row .col-md-5,
.footer_area .row .col-md-6,
.footer_area .row .col-md-7,
.footer_area .row .col-md-8,
.footer_area .row .col-md-9,
.footer_area .row .col-md-auto,
.footer_area .row .col-sm,
.footer_area .row .col-sm-1,
.footer_area .row .col-sm-10,
.footer_area .row .col-sm-11,
.footer_area .row .col-sm-12,
.footer_area .row .col-sm-2,
.footer_area .row .col-sm-3,
.footer_area .row .col-sm-4,
.footer_area .row .col-sm-5,
.footer_area .row .col-sm-6,
.footer_area .row .col-sm-7,
.footer_area .row .col-sm-8,
.footer_area .row .col-sm-9,
.footer_area .row .col-sm-auto,
.footer_area .row .col-xl,
.footer_area .row .col-xl-1,
.footer_area .row .col-xl-10,
.footer_area .row .col-xl-11,
.footer_area .row .col-xl-12,
.footer_area .row .col-xl-2,
.footer_area .row .col-xl-3,
.footer_area .row .col-xl-4,
.footer_area .row .col-xl-5,
.footer_area .row .col-xl-6,
.footer_area .row .col-xl-7,
.footer_area .row .col-xl-8,
.footer_area .row .col-xl-9,
.footer_area .row .col-xl-auto {
	padding-right: 25px;
	padding-left: 25px;
}

.single-footer-widget {
	position: relative;
	z-index: 1;
}
.single-footer-widget .copywrite-text a {
	color: #747794;
	font-size: 1rem;
}
.single-footer-widget .copywrite-text a:hover,
.single-footer-widget .copywrite-text a:focus {
	color: #ff695d;
}
.single-footer-widget .widget-title {
	margin-bottom: 1.5rem;
}
.single-footer-widget .footer_menu li a {
	color: #747794;
	margin-bottom: 1rem;
	display: block;
	font-size: 1rem;
}
.single-footer-widget .footer_menu li a:hover,
.single-footer-widget .footer_menu li a:focus {
	color: #ff695d;
}
.single-footer-widget .footer_menu li:last-child a {
	margin-bottom: 0;
}

.footer_social_area {
	position: relative;
	z-index: 1;
}
.footer_social_area a {
	border-radius: 50%;
	height: 40px;
	text-align: center;
	width: 40px;
	display: inline-block;
	background-color: #ffd0cc;
	line-height: 40px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-right: 10px;
	color: #ff695d;
}
.footer_social_area a i {
	line-height: 36px;
}
.footer_social_area a:hover,
.footer_social_area a:focus {
	color: #ffffff;
}

@-webkit-keyframes bi-cycle {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}

@keyframes bi-cycle {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}
ol li,
ul li {
	list-style: none;
}

ol,
ul {
	margin: 0;
	padding: 0;
}
/* Makemoney */

body {
	margin-top: 20px;
}

.ftco-section {
	border-radius: 22px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.heading-section {
	font-family: "Poppins", sans-serif;
	color: #4a4a4a;
	padding: 25px;
}
.block-7 {
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 0;
	overflow: hidden;
	background: #fff;
	-webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
@media (max-width: 991.98px) {
	.block-7 {
		margin-top: 30px;
	}
}
.block-7 .img {
	height: 250px;
}
.block-7 .heading-2 {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
}
.block-7 .price {
	margin: 0;
	padding: 0;
	display: block;
}
.block-7 .price sup {
	font-size: 24px;
	top: -1em;
	color: #b3b3b3;
}
.block-7 .price .number {
	font-size: 60px;
	font-weight: 600;
	color: #000000;
}
.block-7 .excerpt {
	margin-bottom: 0px;
	color: #ff695d;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
}
.block-7 .label2 {
	text-transform: uppercase;
}
.block-7 .pricing-text,
.block-7 .pricing-text li {
	padding: 0;
	margin: 0;
}
.block-7 .pricing-text li {
	list-style: none;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #000000;
}
.block-7 .pricing-text li:nth-child(odd) {
	background: rgba(0, 0, 0, 0.05);
}
.block-7 .pricing-text li span.fa {
	color: #207dff;
}
.block-7 .btn-primary {
	color: #fff;
	text-transform: uppercase;
	font-style: 16px;
	font-weight: 600;
	letter-spacing: 1px;
	width: 60%;
	margin: 0 auto;
}
.block-7 .btn-primary:hover,
.block-7 .btn-primary:focus {
	background: #747794 !important;
	color: #fff;
}
.block-7:hover,
.block-7:focus {
	-webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
	-moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
	box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
}

.btn-primary {
	background-color: #ff695d;
	border: #ff695d;
}

/* Reviews */

.section-medium {
	border-radius: 22px;
	padding: 20px;
}

.btn.btn-outline-primary:hover,
.bg-primary {
	background-color: #ff695d !important;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
	color: white !important;
}
.section-title {
	font-size: 42px;
	margin-top: 10px;
	margin-bottom: 10px;
	/* color: #bf24d4; */
	font-family: "Poppins", sans-serif;
	background-color: #ff695d;
}
.section-sub-title {
	font-size: 18px;
	background-color: #ff695d;
	font-family: "Didact Gothic", sans-serif;
}
.section-arrow-primary-color.section-arrow--bottom-center:after {
	border-bottom-color: #0cb4ce;
}
.section-arrow-primary-color.section-arrow--bottom-center:after {
	background-color: #0cb4ce;
}
.special-heading.line span:before,
.special-heading.line span:after,
.footer.footer-minimal,
.t-bordered {
	border-top-color: #eaeaea;
}
.t-bordered {
	border-top: 1px solid #eaeaea;
}
.section-primary {
}
section {
	position: relative;
}

.testimonial-two {
	padding: 20px;
	border: 2px solid #0cb4ce;
	border-radius: 2px;
}

.testimonial-two blockquote p:before {
	font-family: "Playfair Display";
	font-size: 54px;
	color: #0cb4ce;
	font-weight: 900;
	left: 10px;
	color: #fff;
	content: "“";
	font-style: normal;
	line-height: 1;
	position: absolute;
	top: 5px;
	font-size: 45px;
}

.testimonial-two blockquote p:after {
	font-family: "Playfair Display";
	font-size: 54px;
	color: #9e0cce;
	content: "”";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	right: 10px;
	font-weight: 900;
	transform: scaleY(-1);
	font-size: 45px;
	bottom: -5px;
}

.testimonial-two blockquote {
	padding: 15px 15px 15px 48px;
	position: relative;
}

.testimonial-two blockquote p:before,
.testimonial-two blockquote p:after {
	color: #0cb4ce;
	font-weight: 900;
}

.testimonial-two blockquote p {
	font-size: inherit;
	font-weight: inherit;
}

.testimonial-two .testimonial-author p {
	color: #999;
	margin: 0 0 0 25px;
	text-align: left;
}

.testimonial-two .testimonial-author strong {
	display: block;
	padding-top: 10px;
	margin-bottom: -2px;
	font-weight: 500;
	font-size: 14px;
	color: #444;
}

.testimonial-two .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial-two .testimonial-author .testimonial-author-thumbnail {
	float: left;
	margin-right: 15px;
	width: auto;
}

.testimonial-two .testimonial-author img {
	max-width: 55px;
}

.testimonial-two .testimonial-author {
	margin-left: 12px;
	margin-bottom: 15px;
}

.testimonial-two .owl-theme .owl-nav.disabled + .owl-dots {
	margin-top: 0px;
}

.testimonial-two .owl-theme .owl-dots {
	text-align: left;
	margin-left: 50px;
}

.testimonial-two .owl-carousel .owl-dots .owl-dot.active span,
.testimonial-two .owl-carousel .owl-dots .owl-dot:hover span {
	border-color: #0cb4ce;
}

.testimonial-two .owl-carousel .owl-dots .owl-dot.active span:before,
.testimonial-two .owl-carousel .owl-dots .owl-dot:hover span:before {
	background-color: #0cb4ce;
}

.testimonial-three .testimonial-image {
	float: left;
	margin: 0 20px 0 0;
	width: 80px;
	height: 80px;
	border-radius: 1000px;
	border: 2px solid #ff695d;

	overflow: hidden;
}

.testimonial-three .testimonial-content {
	overflow: hidden;
}

.testimonial-three .testimonial-meta {
	position: relative;
	overflow: hidden;
	margin-left: 100px;
}

.testimonial-three .testimonial-name {
	display: block;
}

.testimonial-three .testimonial-three-col {
	padding-bottom: 35px;
}

.testimonial-three .testimonial-three-col {
	border-right-style: dashed;
	border-right-width: 1px;
	border-bottom-style: dashed;
	border-bottom-width: 1px;
	border-right-color: #ddd;
	border-bottom-color: #ddd;
	padding: 25px;
}

.testimonial-three .testimonial-three-col:hover {
	background-color: #f7f7f7;
}

.alternate-color .testimonial-three .testimonial-three-col:hover {
	background-color: white;
}

.testimonial-three.testimonial-three--col-two
	.testimonial-three-col:nth-child(2n) {
	border-right: none;
}

.testimonial-three.testimonial-three--col-two
	.testimonial-three-col:nth-last-child(-n + 2),
.testimonial-three.testimonial-three--col-two
	.testimonial-three-col:last-child {
	border-bottom: none;
}

.testimonial-three.testimonial-three--col-three
	.testimonial-three-col:nth-child(3n) {
	border-right: none;
}

.testimonial-three.testimonial-three--col-three
	.testimonial-three-col:nth-last-child(-n + 3),
.testimonial-three.testimonial-three--col-three
	.testimonial-three-col:nth-last-child(-n + 2),
.testimonial-three.testimonial-three--col-three
	.testimonial-three-col:last-child {
	border-bottom: none;
}

.testimonial-four .testimonial-author .testimonial-author-thumbnail {
	float: left;
	margin-right: 15px;
	width: auto;
}

.testimonial-four .testimonial-author img {
	max-width: 55px;
}

.testimonial-four .testimonial-author strong {
	display: block;
	padding-top: 10px;
	margin-bottom: -2px;
	font-weight: 500;
	font-size: 14px;
	color: #444;
}

.testimonial-four .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial-four blockquote {
	padding: 30px;
	width: 100%;
	border-radius: 4px;
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 25px;
	border: 2px solid #eaeaea;
}

.testimonial-four blockquote::after {
	content: "";
	border-right: 2px solid #eaeaea;
	border-bottom: 2px solid #eaeaea;
	-webkit-transform: rotate(55deg);
	-moz-transform: rotate(55deg);
	-ms-transform: rotate(55deg);
	-o-transform: rotate(55deg);
	transform: rotate(55deg);
	position: absolute;
	left: 55px;
	bottom: -12px;
	width: 15px;
	height: 21px;
	overflow: hidden;
	background: white;
}

.testimonial-four .owl-theme .owl-nav.disabled + .owl-dots {
	position: absolute;
	float: right;
	text-align: right;
	right: 3px;
	bottom: 40px;
}

.testimonial-four .testimonial-author {
	position: relative;
	overflow: hidden;
}

.testimonial-five blockquote p {
	font-weight: 400;
	font-size: 14.58px;
	line-height: 1.6;
	margin-bottom: 0;
}

.testimonial-five .testimonial-author .testimonial-author-thumbnail {
	float: left;
	margin-right: 15px;
	width: auto;
}

.testimonial-five .testimonial-author img {
	max-width: 55px;
}

.testimonial-five .testimonial-author strong {
	display: block;
	padding-top: 10px;
	margin-bottom: -2px;
	font-weight: 600;
	font-size: 13px;
}

.testimonial-five .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial-five blockquote {
	padding: 32px 41px 37px;
	width: 100%;
	border-radius: 4px;
	position: relative;
	margin-bottom: 30px;
	border: 0px solid #eaeaea;
	background-color: #f7f7f7;
}

.testimonial-five blockquote::after {
	content: "";
	border-right: 0px solid #eaeaea;
	border-bottom: 0px solid #eaeaea;
	-webkit-transform: rotate(55deg);
	-moz-transform: rotate(55deg);
	-ms-transform: rotate(55deg);
	-o-transform: rotate(55deg);
	transform: rotate(55deg);
	position: absolute;
	left: 55px;
	bottom: -10px;
	width: 15px;
	height: 21px;
	overflow: hidden;
	background: #f7f7f7;
}

.testimonial-five.testimonial-light blockquote {
	background-color: white;
}
.testimonial-five.testimonial-light blockquote::after {
	background: white;
}

.testimonial-five .owl-theme .owl-nav.disabled + .owl-dots {
	position: absolute;
	float: right;
	text-align: right;
	right: 3px;
	bottom: 40px;
}

.testimonial-five .testimonial-author {
	position: relative;
	overflow: hidden;
}

.testimonial-five blockquote:before {
	content: "“";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 170px;
	color: rgba(189, 189, 189, 0.2);
	position: absolute;
	top: 10px;
	left: 20px;
}
img {
	max-width: 100%;
	height: auto;
}

.social {
	display: flex;
	justify-content: center;
}
